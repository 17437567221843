import axios from "axios";

export async function fetchLogin(email, password) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `http://147.79.107.146:3000/login`, // Adicionando o idcliente na URL como parâmetro
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
