import axios from "axios";

export async function fetchAuth(iduser) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `http://147.79.107.146:3000/auth`, // Adicionando o idcliente na URL como parâmetro
        {
          id: parseInt(iduser),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
